import * as React from "react"
import { Code, Menu, X, ChevronDown, Sun, Moon } from "lucide-react"
import { Link } from "react-router-dom"
import FreePythonDropdown from "./FreePythonDropdown"
import { useTheme } from "../../Contexts/ThemeContext"

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [isLeaderboardOpen, setIsLeaderboardOpen] = React.useState(false)
  const [isChallengeOpen, setIsChallengeOpen] = React.useState(false)
  const [mounted, setMounted] = React.useState(false)
  const isDesktop = useMediaQuery("(min-width: 1024px)")

  React.useEffect(() => {
    setMounted(true)
  }, [])

  const token = localStorage.getItem("token")

  const menuItems = [
    // { label: "Research", href: "/search", color: "text-purple-500" },
    // { label: "FREE 7 Days", href: "/landing", color: "text-yellow-500" },
    { label: "Courses", href: "/courses", color: "text-orange-500" },
    { label: "Careers", href: "/all-learning-paths", color: "text-blue-500" },
  ]

  const leaderboardItems = [
    { label: "Time Spent", href: "/leaderboard", color: "text-green-500" },
    { label: "Streak", href: "/streak", color: "text-purple-500" },
  ]

  const challengeItems = [
    { label: "Challenge", href: "/challenge", color: "text-pink-500" },
    { label: "Workspace", href: "/workspace", color: "text-indigo-500" },
  ]

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const toggleLeaderboard = () => {
    setIsLeaderboardOpen(!isLeaderboardOpen)
  }

  const toggleChallenge = () => {
    setIsChallengeOpen(!isChallengeOpen)
  }

  const { isDarkMode, toggleDarkMode } = useTheme();

  if (!mounted) return null

  return (
    // here
    <header className={`${isDarkMode ? "bg-customBodyDarken2" : " border-b border-gray-200 bg-white"} py-4 font-mono shadow-sm transition-shadow duration-300 ease-in-out hover:shadow-md `}>
      <div className={`${isDarkMode ? "bg-customBodyDarken2" : " bg-white"} container mx-auto flex items-center justify-between px-4`}>
        <Link to="/" className="group flex items-center space-x-2">
          <Code className="h-8 w-8 text-orange-500 transition-transform duration-300 ease-in-out group-hover:rotate-12" />
          <span className="text-xl font-semibold">
            <span className="text-blue-500 transition-colors duration-300 ease-in-out group-hover:text-blue-600">Code</span>
            <span className="text-blue-500 transition-colors duration-300 ease-in-out group-hover:text-blue-600">For</span>
            <span className="text-blue-500 transition-colors duration-300 ease-in-out group-hover:text-blue-600">Cambodia</span>
            <span className="text-orange-500 transition-colors duration-300 ease-in-out group-hover:text-orange-600">;</span>
          </span>
        </Link>

        {isDesktop ? (
          <nav className="flex items-center space-x-4">

            {/*  */}
            {menuItems.map((item) => (
              <Link
                key={item.href}
                to={item.href}
                className={`text-base ${item.color} hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-y-0.5`}
              >
                {item.label}
              </Link>
            ))}

            {/* <div className="relative">
              <button
                onClick={toggleChallenge}
                className="flex items-center text-base text-indigo-500 hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-y-0.5"
              >
                Challenge <ChevronDown className="ml-1 h-4 w-4" />
              </button>
              {isChallengeOpen && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[100]">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    {challengeItems.map((item) => (
                      <Link
                        key={item.href}
                        to={item.href}
                        className={`block px-4 py-2 text-sm ${item.color} hover:bg-gray-100`}
                        role="menuitem"
                      >
                        {item.label}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div> */}

            <div className="relative">
              <button
                onClick={toggleLeaderboard}
                className="flex items-center text-base text-green-500 hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-y-0.5"
              >
                Leaderboard <ChevronDown className="ml-1 h-4 w-4" />
              </button>
              {isLeaderboardOpen && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[100]">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    {leaderboardItems.map((item) => (
                      <Link
                        key={item.href}
                        to={item.href}
                        className={`block px-4 py-2 text-sm ${item.color} hover:bg-gray-100`}
                        role="menuitem"
                      >
                        {item.label}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {!token && (
              <>
                <Link
                  to="/login"
                  className="rounded-md bg-white Nokora px-4 py-2 text-base font-medium text-blue-500 hover:bg-blue-50 transition-all duration-300 ease-in-out hover:-translate-y-0.5 hover:shadow-sm"
                >
                  Log in
                </Link>
                <Link
                  to="/register"
                  className="rounded-md Nokora bg-blue-500 px-4 py-2 text-base font-medium text-white hover:bg-blue-600 transition-all duration-300 ease-in-out hover:-translate-y-0.5 hover:shadow-sm"
                >
                  ចុះឈ្មោះ
                </Link>
              </>
            )}
            {/* darkMode */}
            <button onClick={toggleDarkMode} >
              {isDarkMode ? <Moon className="text-white size-5 opacity-75 hover:opacity-40 transition-all duration-100" /> : <Sun className="text-gray-600 opacity-50 hover:opacity-90 size-5 hover:text-gray-600 transition-all duration-100" />}
            </button>
          </nav>
        ) : (
          <button
            onClick={toggleMenu}
            className="rounded-md p-2 text-purple-500 hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300 ease-in-out hover:rotate-180"
          >
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
            <span className="sr-only">Toggle menu</span>
          </button>
        )}
      </div>

      {!isDesktop && (
        <nav className={`container mx-auto space-y-2 px-4 overflow-hidden transition-all duration-300 ease-in-out ${isMenuOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'} ${isDesktop ? "mt-4" : ""}`}>
          {menuItems.map((item, index) => (
            <Link
              key={item.href}
              to={item.href}
              className={`block py-2 text-base ${item.color} hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-x-1`}
              onClick={toggleMenu}
              style={{ transitionDelay: `${index * 50}ms` }}
            >
              {item.label}
            </Link>
          ))}

          <div className="py-2">
            <button
              onClick={toggleChallenge}
              className="flex items-center text-base text-indigo-500 hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-x-1"
            >
              Challenge <ChevronDown className="ml-1 h-4 w-4" />
            </button>
            {isChallengeOpen && (
              <div className="pl-4 mt-2 space-y-2">
                {challengeItems.map((item, index) => (
                  <Link
                    key={item.href}
                    to={item.href}
                    className={`block py-2 text-sm ${item.color} hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-x-1`}
                    onClick={toggleMenu}
                    style={{ transitionDelay: `${(menuItems.length + index) * 50}ms` }}
                  >
                    {item.label}
                  </Link>
                ))}
              </div>
            )}
          </div>

          <div className="py-2">
            <button
              onClick={toggleLeaderboard}
              className="flex items-center text-base text-green-500 hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-x-1"
            >
              Leaderboard <ChevronDown className="ml-1 h-4 w-4" />
            </button>
            {isLeaderboardOpen && (
              <div className="pl-4 mt-2 space-y-2">
                {leaderboardItems.map((item, index) => (
                  <Link
                    key={item.href}
                    to={item.href}
                    className={`block py-2 text-sm ${item.color} hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-x-1`}
                    onClick={toggleMenu}
                    style={{ transitionDelay: `${(menuItems.length + challengeItems.length + index) * 50}ms` }}
                  >
                    {item.label}
                  </Link>
                ))}
              </div>
            )}
          </div>
          {/* Darkmode Mobile View */}
          <div className="py-2">
            <button onClick={toggleDarkMode} className={`flex items-center text-base ${isDarkMode?"text-customBodyText":"text-gray-600 "}  gap-2 hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-x-1`}>
              {isDarkMode ? <Moon className="text-white size-5 opacity-75 hover:opacity-40 transition-all duration-100" /> : <Sun className="text-gray-600 opacity-50 hover:opacity-90 size-5 hover:text-gray-600 transition-all duration-100" />}
              {isDarkMode ? "Dark mode" : "Light mode"}
            </button>
            
          </div>
          {!token && (
            <>
              <Link
                to="/login"
                className="block py-2 text-base text-blue-500 hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-x-1"
                onClick={toggleMenu}
                style={{ transitionDelay: `${(menuItems.length + leaderboardItems.length + challengeItems.length) * 50}ms` }}
              >
                Log in
              </Link>
              <Link
                to="/register"
                className="block py-2 text-base text-orange-500 hover:opacity-80 transition-all duration-300 ease-in-out hover:-translate-x-1"
                onClick={toggleMenu}
                style={{ transitionDelay: `${(menuItems.length + leaderboardItems.length + challengeItems.length + 1) * 50}ms` }}
              >
                Sign up
              </Link>
            </>
          )}
        </nav>
      )}
    </header>
  )
}

function useMediaQuery(query) {
  const [matches, setMatches] = React.useState(false)

  React.useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    media.addListener(listener)
    return () => media.removeListener(listener)
  }, [matches, query])

  return matches
}