"use client"

import React, { useState, useEffect, useMemo } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { ChevronRight, ChevronDown, FileText, Award, Code, Book, CheckCircle, PlayCircle, Lock, ClipboardList, NotebookPen, AlertTriangle, X, Puzzle, Gamepad2, Palette, Sparkles, Trophy } from 'lucide-react'
import axios from 'axios'
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader'
import Footer from '../../Components/Footer'
import CodeLoadingAnimation from '../../Components/CodeLoadingAnimation'
import LessonCard from '../../Components/Lessons/LessonCard'
import { motion, AnimatePresence } from 'framer-motion'
import { checkCourseAccess } from '../../Components/functions/courseAccess'
import CourseErrorModal from '../../Components/Course/CourseErrorModal'
import CourseStatusBadge from '../../Components/Course/CourseStatusBadge'
import PremiumTutorialModal from '../../Components/Dashboard/PremiumTutorialModal'
import { useTheme } from '../../Contexts/ThemeContext'

// Scratch-themed colors - updated with more vibrant options and better contrast
const scratchColors = {
  primary: '#4C97FF',    // Scratch blue
  secondary: '#9966FF',  // Scratch purple
  tertiary: '#FF8C1A',   // Scratch orange
  quaternary: '#FFBF00', // Scratch yellow
  quinary: '#5CB1D6',    // Scratch light blue
  senary: '#59C059',     // Scratch green
  septenary: '#CF63CF',  // Scratch pink
  octonary: '#FF6680',   // Bright pink - added for module 8
  nonary: '#4DD2FF',     // Bright cyan - added for module 9+
  background: '#F9F9F9',
  darkBackground: '#1E1E2D',
  darkText: '#FFFFFF'
}

export default function ScratchCoursePage() {
  const [course, setCourse] = useState(null)
  const [modules, setModules] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [expandedModule, setExpandedModule] = useState(null)
  const [userProgress, setUserProgress] = useState(null)
  const [averageGrade, setAverageGrade] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [notification, setNotification] = useState(null)
  const [courseAccessStatus, setCourseAccessStatus] = useState({ isFree: true, hasAccess: true })
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showPremiumModal, setShowPremiumModal] = useState(false)
  const [moduleLoadingStates, setModuleLoadingStates] = useState({})

  const courseId = 32;
  const { isDarkMode } = useTheme();
  const navigate = useNavigate()

  const showNotification = (message, type) => {
    setNotification({ message, type })
    setTimeout(() => setNotification(null), 3000)
  }
  
  const resetProgress = async () => {
    setIsDeleting(true)
    try {
      const token = localStorage.getItem('token')
      const headers = { 'Authorization': `Bearer ${token}` }
      await axios.post('https://codeforcambodia.codes/codecademy/user/delete/progress', { course_id: courseId }, { headers })
      showNotification('វឌ្ឍនភាពត្រូវបានលុបដោយជោគជ័យ', 'success')
      setUserProgress(null)

      // Refresh the page after successful reset
      window.location.reload()
    } catch (error) {
      console.error('Error deleting progress:', error)
      showNotification('មានបញ្ហាក្នុងការលុបវឌ្ឍនភាព', 'error')
    } finally {
      setIsDeleting(false)
      setIsDeleteModalOpen(false)
    }
  }

  const totalLessons = useMemo(() => 
    modules.reduce((acc, module) => acc + module.lessons.length, 0),
    [modules]
  );

  useEffect(() => {
    const controller = new AbortController();
    
    const fetchCourseAndModules = async () => {
      try {
        const token = localStorage.getItem('token')
        const headers = { 
          'Authorization': `Bearer ${token}`,
          signal: controller.signal 
        }

        // First fetch everything except lessons
        const [modulesResponse, progressResponse, coursesResponse, averageGradeResponse, courseAccess] = await Promise.all([
          axios.get(`https://codeforcambodia.codes/codecademy/fetch/courses/${courseId}/modules`, { headers }),
          axios.get(`https://codeforcambodia.codes/codecademy/user/progress`, { headers }),
          axios.get(`https://codeforcambodia.codes/codecademy/fetch/courses/${courseId}`, { headers }),
          axios.get(`https://codeforcambodia.codes/codecademy/gamify/courses/${courseId}/average-grade`, { headers }),
          checkCourseAccess(courseId)
        ])

        setCourseAccessStatus(courseAccess)
        
        const coursesData = coursesResponse.data
        const modulesData = modulesResponse.data
        
        // Set initial modules without lessons
        setModules(modulesData.map(module => ({ ...module, lessons: [] })))
        setCourse({ course_name: coursesData.course_name, description: coursesData.description })

        const courseProgress = progressResponse.data.courses.find(course => course.course_id === parseInt(courseId))
        setUserProgress(courseProgress)
        setAverageGrade(averageGradeResponse.data)
        setLoading(false)

        // Then fetch lessons for each module separately
        modulesData.forEach(async (module) => {
          setModuleLoadingStates(prev => ({ ...prev, [module.id]: true }))
          try {
            const lessonsResponse = await axios.get(
              `https://codeforcambodia.codes/codecademy/fetch/courses/${courseId}/modules/${module.id}/lessons`,
              { headers }
            )
            
            setModules(prevModules => 
              prevModules.map(m => 
                m.id === module.id 
                  ? { ...m, lessons: lessonsResponse.data }
                  : m
              )
            )
          } catch (err) {
            console.error(`Error fetching lessons for module ${module.id}:`, err)
          } finally {
            setModuleLoadingStates(prev => ({ ...prev, [module.id]: false }))
          }
        })

      } catch (err) {
        if (axios.isCancel(err)) {
          console.log('Request cancelled')
          return
        }
        setError(err.message)
        setLoading(false)
      }
    }

    fetchCourseAndModules()
    return () => controller.abort()
  }, [courseId])

  const isLessonLocked = (moduleIndex, lessonIndex) => {
    if (!userProgress) return true
    const currentModuleIndex = modules.findIndex(module => module.id === userProgress.current_module_id)
    const currentLessonIndex = modules[currentModuleIndex]?.lessons.findIndex(lesson => lesson.id === userProgress.current_lesson_id)

    return moduleIndex > currentModuleIndex || (moduleIndex === currentModuleIndex && lessonIndex > currentLessonIndex)
  }

  // Update the rendering of module lessons to include moduleIndex parameter
  const renderModuleLessons = (module, moduleIndex) => {
    if (moduleLoadingStates[module.id]) {
      return <div className="p-4 text-center text-gray-500">កំពុងផ្ទុកមេរៀន...</div>
    }

    return module.lessons.map((lesson, lessonIndex) => (
      <LessonCard
        key={lesson.id}
        lesson={lesson}
        courseId={courseId}
        moduleId={module.id}
        isLocked={isLessonLocked(moduleIndex, lessonIndex)}
        userProgress={userProgress}
        courseAccessStatus={courseAccessStatus}
        onContinueCourse={() => {
          navigate(`/courses/${courseId}/modules/${userProgress.current_module_id}/lessons/${userProgress.current_lesson_id}`)
        }}
      />
    ))
  }

  if (loading) {
    return <CodeLoadingAnimation />
  }

  if (error) {
    return (
      <div className={`flex items-center justify-center h-screen ${isDarkMode?"bg-customBody":"bg-white"} `}>
        <div className="text-xl font-mono text-red-600">Error: {error}</div>
      </div>
    )
  }

  // Get a random color from the Scratch palette for each module
  const getModuleColor = (index) => {
    // Define a specific sequence of colors to ensure good contrast
    const colorSequence = [
      scratchColors.primary,    // Blue
      scratchColors.tertiary,   // Orange
      scratchColors.senary,     // Green
      scratchColors.secondary,  // Purple
      scratchColors.quaternary, // Yellow
      scratchColors.septenary,  // Pink
      scratchColors.quinary,    // Light blue
      scratchColors.octonary,   // Bright pink
      scratchColors.nonary      // Bright cyan
    ];
    
    return colorSequence[index % colorSequence.length];
  }

  return (
    <div className={`flex flex-col min-h-screen font-mono ${isDarkMode ? "bg-customBody text-customBodyText" : "bg-scratch-bg text-indigo-900"}`}
         style={{ backgroundColor: isDarkMode ? scratchColors.darkBackground : scratchColors.background }}>
      <CodeForCambodiaHeader />
      <PremiumTutorialModal isOpen={showPremiumModal} onClose={() => setShowPremiumModal(false)} />

      <main className="flex-grow px-4 py-8">
        <div className="mx-auto max-w-6xl relative">
          {/* Animated decorative elements */}
          <div className="absolute -top-6 -left-6 w-16 h-16 rounded-full bg-yellow-300 opacity-70 animate-bounce"></div>
          <div className="absolute top-20 -right-6 w-12 h-12 rounded-full bg-purple-400 opacity-70 animate-pulse"></div>
          <div className="absolute bottom-20 -left-10 w-14 h-14 rounded-full bg-green-400 opacity-70 animate-pulse"></div>
          <div className="absolute bottom-40 right-0 w-10 h-10 rounded-full bg-blue-400 opacity-70 animate-bounce"></div>
          
          {/* Add Scratch Cat animation */}
          <motion.div 
            className="absolute top-10 right-10 hidden md:block z-10"
            animate={{ 
              y: [0, -15, 0],
              rotate: [0, 5, 0, -5, 0]
            }}
            transition={{ 
              repeat: Infinity, 
              duration: 5,
              ease: "easeInOut"
            }}
          >
            <img src="https://en.scratch-wiki.info/w/images/thumb/ScratchCat-Small.png/200px-ScratchCat-Small.png" alt="Scratch Cat" className="w-28 h-28" />
            <motion.div 
              className="absolute -bottom-2 -right-2 bg-yellow-200 text-yellow-800 px-2 py-1 rounded-full text-xs font-bold"
              initial={{ scale: 0 }}
              animate={{ scale: [0, 1.2, 1] }}
              transition={{ delay: 1, duration: 0.5 }}
            >
              Let's code!
            </motion.div>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className={`rounded-xl p-8 mb-8 ${isDarkMode ? "bg-indigo-900/30" : "bg-white"} shadow-lg border-4`}
            style={{ borderColor: scratchColors.primary, boxShadow: isDarkMode ? '0 10px 25px rgba(76, 151, 255, 0.2)' : '0 10px 25px rgba(76, 151, 255, 0.1)' }}
          >
            {/* Course title with playful design */}
            <div className="flex items-center mb-6">
              <div className="relative mr-4">
                <Puzzle className="text-blue-600" size={40} />
                <motion.div 
                  animate={{ rotate: [0, 10, 0, -10, 0] }}
                  transition={{ repeat: Infinity, duration: 5 }}
                  className="absolute -top-2 -right-2 w-4 h-4 rounded-full bg-yellow-300"
                />
              </div>
              <div>
                <h1 className="text-4xl font-bold Nokora bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-600">
                  {course.course_name}
                </h1>
                <CourseStatusBadge courseAccessStatus={courseAccessStatus} />
              </div>
            </div>

            <div className="mb-6 pl-4 border-l-4 py-3 rounded-l-lg" 
                 style={{ 
                   borderColor: scratchColors.tertiary,
                   backgroundColor: isDarkMode ? 'rgba(255, 140, 26, 0.1)' : 'rgba(255, 140, 26, 0.05)'
                 }}>
              <p className={`text-lg ${isDarkMode ? "text-customBodyText" : "text-indigo-800"} Nokora`}>
                {course.description}
              </p>
            </div>

            {/* Course stats with game-like badges */}
            <div className="flex flex-wrap justify-between mb-8 gap-4 Nokora">
              <motion.div 
                whileHover={{ scale: 1.05 }}
                className="flex items-center px-5 py-3 rounded-full shadow-md" 
                style={{ backgroundColor: isDarkMode ? 'rgba(92, 177, 214, 0.3)' : 'rgba(92, 177, 214, 0.2)' }}
              >
                <Gamepad2 className="w-6 h-6 mr-3" style={{ color: scratchColors.quinary }} />
                <span className="font-bold text-lg">{modules.length} ជំពូក</span>
              </motion.div>
              <motion.div 
                whileHover={{ scale: 1.05 }}
                className="flex items-center px-5 py-3 rounded-full shadow-md" 
                style={{ backgroundColor: isDarkMode ? 'rgba(89, 192, 89, 0.3)' : 'rgba(89, 192, 89, 0.2)' }}
              >
                <Palette className="w-6 h-6 mr-3" style={{ color: scratchColors.senary }} />
                <span className="font-bold text-lg">{totalLessons} មេរៀន</span>
              </motion.div>
            </div>

            {/* Continue Learning or Start Course button - more playful */}
            <div className="mb-6 Nokora flex flex-col space-y-4">
              <div className="flex items-center space-x-4">
                {userProgress ? (
                  <>
                    <Link
                      to={
                        !courseAccessStatus.isFree && !courseAccessStatus.hasAccess
                          ? '#'
                          : `/courses/${courseId}/modules/${userProgress.current_module_id}/lessons/${userProgress.current_lesson_id}`
                      }
                      onClick={(e) => {
                        if (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess) {
                          e.preventDefault();
                          showNotification('មេរៀននេះត្រូវការ premium', 'error');
                        }
                      }}
                      className={`bg-gradient-to-r from-green-500 to-teal-400 text-white px-8 py-4 rounded-full transition-all duration-300 inline-flex items-center text-lg font-bold shadow-lg hover:shadow-xl transform hover:-translate-y-1 ${
                        (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess)
                          ? 'opacity-50 cursor-not-allowed'
                          : ''
                      }`}
                    >
                      <PlayCircle className="mr-3" size={24} />
                      បន្តរការរៀន
                    </Link>
                    <button
                      onClick={() => setIsDeleteModalOpen(true)}
                      className="text-red-500 hover:text-red-700 transition-colors duration-200 text-base flex items-center bg-red-100 px-4 py-2 rounded-full hover:bg-red-200 dark:bg-red-900/30 dark:hover:bg-red-900/50"
                    >
                      <AlertTriangle className="mr-2" size={18} />
                      រៀនឡើងវិញ
                    </button>
                  </>
                ) : (
                  <Link
                    to={
                      !courseAccessStatus.isFree && !courseAccessStatus.hasAccess
                        ? '#'
                        : `/courses/${courseId}/modules/${modules[0]?.id}/lessons/${modules[0]?.lessons[0]?.id}`
                    }
                    onClick={(e) => {
                      if (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess) {
                        e.preventDefault();
                        showNotification('មេរៀននេះត្រូវការ premium', 'error');
                      }
                    }}
                    className={`bg-gradient-to-r from-blue-500 to-purple-500 text-white px-8 py-4 rounded-full transition-all duration-300 inline-flex items-center text-lg font-bold shadow-lg hover:shadow-xl transform hover:-translate-y-1 ${
                      (!courseAccessStatus.isFree && !courseAccessStatus.hasAccess)
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    }`}
                  >
                    <Sparkles className="mr-3" size={24} />
                    ចាប់ផ្តើមការរៀន
                  </Link>
                )}
              </div>

              {/* Premium button - more attractive */}
              {!courseAccessStatus.isFree && !courseAccessStatus.hasAccess && (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPremiumModal(true);
                  }}
                  className="text-yellow-600 dark:text-yellow-400 ml-2 transition-colors duration-200 text-base flex items-center bg-yellow-100 dark:bg-yellow-900/30 px-4 py-2 rounded-full hover:bg-yellow-200 dark:hover:bg-yellow-900/50 self-start"
                >
                  <Award className="mr-2 text-yellow-500" size={20} />
                  របៀបបាន pro
                </motion.button>
              )}

              <CourseErrorModal
                showModal={showErrorModal}
                setShowModal={setShowErrorModal}
                courseAccessStatus={courseAccessStatus}
                onContinueCourse={() => {
                  navigate(`/courses/${courseId}/modules/${userProgress?.current_module_id}/lessons/${userProgress?.current_lesson_id}`)
                }}
                userProgress={userProgress}
              />
            </div>

            {/* Course Progress - fixed and improved contrast */}
            {userProgress && (
              <div className="mb-10 p-6 rounded-xl" style={{ 
                backgroundColor: isDarkMode ? 'rgba(76, 151, 255, 0.2)' : 'rgba(76, 151, 255, 0.1)',
                border: `2px dashed ${scratchColors.primary}`
              }}>
                <h3 className={`text-2xl mb-4 Nokora font-bold flex items-center`} style={{ color: isDarkMode ? '#FFFFFF' : scratchColors.primary }}>
                  <Trophy className="mr-2" size={24} />
                  វឌ្ឍនភាពការរៀន
                </h3>
                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-4 mb-3 overflow-hidden">
                  <div 
                    className="h-4 rounded-full relative" 
                    style={{ 
                      width: `${userProgress.progress_percentage}%`,
                      background: `linear-gradient(to right, ${scratchColors.senary}, ${scratchColors.primary})`
                    }}
                  >
                    {userProgress.progress_percentage > 5 && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <span className="text-sm text-white font-bold drop-shadow-md">
                          {parseFloat(userProgress.progress_percentage.toString()).toFixed(0)}%
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <p className={`text-base font-bold Nokora ${isDarkMode ? "text-white" : "text-blue-700"}`}>
                    {parseFloat(userProgress.progress_percentage.toString()).toFixed(2)}% ចប់
                  </p>
                  {userProgress.progress_percentage >= 100 && (
                    <div className="flex items-center text-yellow-500 bg-yellow-100 dark:bg-yellow-900/30 px-3 py-1 rounded-full">
                      <Sparkles className="mr-1" size={18} />
                      <span className="font-bold">ចប់សព្វគ្រប់!</span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Course Syllabus - more playful */}
            <h2 className="text-3xl font-bold mb-6 flex items-center" style={{ color: scratchColors.secondary }}>
              <Book className="mr-3" size={28} />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-indigo-500">
                Course Syllabus
              </span>
            </h2>
            <ul className="space-y-6">
              {modules.map((module, moduleIndex) => {
                const moduleColor = getModuleColor(moduleIndex);
                return (
                  <motion.li 
                    key={module.id} 
                    className={`border-2 rounded-xl overflow-hidden shadow-md`}
                    style={{ borderColor: moduleColor }}
                    whileHover={{ y: -5, boxShadow: '0 10px 25px rgba(0,0,0,0.1)' }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <button
                      onClick={() => setExpandedModule(expandedModule === module.id ? null : module.id)}
                      className={`w-full text-left flex items-center justify-between p-5 transition-colors duration-200`}
                      style={{ 
                        backgroundColor: isDarkMode 
                          ? `${moduleColor}40` 
                          : `${moduleColor}20`
                      }}
                      aria-expanded={expandedModule === module.id}
                      aria-controls={`module-content-${module.id}`}
                    >
                      <div className="flex items-center">
                        <div 
                          className="w-14 h-14 rounded-full flex items-center justify-center text-white font-bold mr-4 shadow-md"
                          style={{ backgroundColor: moduleColor }}
                        >
                          {moduleIndex + 1}
                        </div>
                        <div>
                          <h3 className="text-xl font-bold Nokora" style={{ color: isDarkMode ? '#FFFFFF' : moduleColor }}>
                            {module.module_name}
                          </h3>
                          <p className={`text-sm ${isDarkMode ? "text-white" : "text-indigo-900"} Nokora flex items-center space-x-3 mt-2`}>
                            <span className="flex items-center">
                              <FileText className="w-4 h-4 mr-1" />
                              <span>
                                {module.lessons.filter(lesson => lesson.type === 'document' || lesson.type === 'video').length} ឯកសារ
                              </span>
                            </span>
                            <span className="flex items-center ml-2">
                              <Code className="w-4 h-4 mr-1" />
                              <span>
                                {module.lessons.filter(lesson =>
                                  lesson.type === 'coding' ||
                                  lesson.type === 'web' ||
                                  lesson.type === 'scratch'
                                ).length} លំហាត់
                              </span>
                            </span>
                            <span className="flex items-center ml-2">
                              <NotebookPen className="w-4 h-4 mr-1" />
                              <span>
                                {module.lessons.filter(lesson => lesson.type === 'quiz').length} ប្រលង
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                      <motion.div
                        animate={{ rotate: expandedModule === module.id ? 90 : 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <ChevronRight className="w-6 h-6 flex-shrink-0" style={{ color: isDarkMode ? '#FFFFFF' : moduleColor }} />
                      </motion.div>
                    </button>
                    <AnimatePresence>
                      {expandedModule === module.id && (
                        <motion.ul 
                          id={`module-content-${module.id}`} 
                          className={`${isDarkMode ? "bg-indigo-900/50" : "bg-white"} p-5 space-y-3`}
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: "auto", opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          {renderModuleLessons(module, moduleIndex)}
                        </motion.ul>
                      )}
                    </AnimatePresence>
                  </motion.li>
                );
              })}
            </ul>
          </motion.div>
        </div>
      </main>
      
      {/* Add a small Scratch Cat at the bottom for mobile */}
      <motion.div 
        className="fixed bottom-20 right-4 md:hidden z-10"
        animate={{ 
          y: [0, -10, 0],
          rotate: [0, 5, 0]
        }}
        transition={{ 
          repeat: Infinity, 
          duration: 3,
          ease: "easeInOut"
        }}
      >
        <img src="/images/scratch-cat.png" alt="Scratch Cat" className="w-16 h-16" />
      </motion.div>
      
      <Footer />

      {/* Delete Progress Modal - improved contrast */}
      <AnimatePresence>
        {isDeleteModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className={`${isDarkMode ? "bg-indigo-900" : "bg-white"} rounded-xl p-6 max-w-sm w-full m-4 border-4`}
              style={{ borderColor: scratchColors.tertiary }}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className={`text-xl font-bold ${isDarkMode ? "text-yellow-300" : "text-orange-600"} Nokora`}>
                  បញ្ជាក់ការរៀនឡើងវិញ
                </h2>
                <button
                  onClick={() => setIsDeleteModalOpen(false)}
                  className={`${isDarkMode ? "text-white hover:text-blue-200" : "text-blue-500 hover:text-blue-700"}`}
                  aria-label="Close modal"
                >
                  <X size={24} />
                </button>
              </div>
              <p className={`${isDarkMode ? "text-white" : "text-indigo-700"} mb-6 Nokora`}>
                តើអ្នកប្រាកដជាចង់លុបវឌ្ឍនភាពរបស់អ្នកមែនទេ? សកម្មភាពនេះមិនអាចត្រឡប់វិញបានទេ។
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setIsDeleteModalOpen(false)}
                  className={`px-4 py-2 ${isDarkMode ? "bg-blue-700 text-white hover:bg-blue-600" : "bg-blue-100 text-blue-800 hover:bg-blue-200"} rounded-full transition-colors Nokora`}
                >
                  បោះបង់
                </button>
                <button
                  onClick={resetProgress}
                  disabled={isDeleting}
                  className="px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed Nokora flex items-center"
                >
                  {isDeleting ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      កំពុងលុប...
                    </>
                  ) : (
                    'លុបវឌ្ឍនភាព'
                  )}
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Notification - more playful */}
      <AnimatePresence>
        {notification && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={`fixed bottom-4 right-4 p-4 rounded-xl shadow-lg flex items-center ${
              notification.type === 'success' ? 'bg-gradient-to-r from-green-500 to-teal-400' : 'bg-gradient-to-r from-red-500 to-pink-500'
            } text-white`}
          >
            {notification.type === 'success' ? (
              <CheckCircle className="mr-3" size={24} />
            ) : (
              <AlertTriangle className="mr-3" size={24} />
            )}
            <span className="Nokora font-bold">{notification.message}</span>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
} 