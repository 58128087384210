import React from 'react';
import { Handshake } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTheme } from '../../Contexts/ThemeContext';

export default function StrategicPartners() {
  const { isDarkMode } = useTheme();
  const partners = [
    {
      name: "Britcham Cambodia",
      logo: "https://static.wixstatic.com/media/6b6032_9fdc35f1bfc54b4596d65b561cfb75e7~mv2.png/v1/fill/w_286,h_236,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/6b6032_9fdc35f1bfc54b4596d65b561cfb75e7~mv2.png",
      link: "https://www.britchamcambodia.org/",
    },
  ];

  return (
    <div className={`w-full bg-gradient-to-b ${isDarkMode ? "bg-customBodyDarken1AndHalf" : "from-white to-gray-50"}  py-16 mb-8`}>
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col items-center">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="flex flex-col items-center mb-8"
          >
            <div className={`flex items-center space-x-2 mb-2`}>
              <Handshake className="w-6 h-6 text-green-500" />
              <span className={`text-lg font-semibold ${isDarkMode ? "text-customHeaderText" : "text-gray-900"}`}>
                Strategic Partners
              </span>
            </div>
          </motion.div>

          <div className="w-full max-w-4xl">
            <div className="flex justify-center">
              {partners.map((partner, index) => (
                <motion.div
                  key={partner.name}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="w-full max-w-md"
                >
                  <a 
                    href={partner.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block w-full transition-transform duration-300 "
                  >
                    <div className="group relative bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 flex items-center justify-between p-6">
                      <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-indigo-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl" />
                      <div className="relative flex flex-col space-y-2 items-center justify-center w-full">
                        <img 
                          src={partner.logo}
                          alt={partner.name}
                          className="h-20 object-contain"
                        />
                        <span className="text-sm font-medium text-gray-900 text-center">{partner.name}</span>
                      </div>
                    </div>
                  </a>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
