import React, { useState } from 'react';
import { Code, Zap, Users, GraduationCap, ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import CodeForCambodiaHeader from '../../Components/Headers/CodeForCambodiaHeader';
import Footer from '../../Components/Footer';
import { useTheme } from '../../Contexts/ThemeContext';

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

const ScratchLandingPage = () => {
  // Auth state (would be connected to your auth system)
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  // Modal state
  const [showModal, setShowModal] = useState(false);
  // Add modal type state
  const [modalType, setModalType] = useState('register'); // 'register' or 'login'
  
  const { isDarkMode } = useTheme();
  
  // Toggle auth for demo purposes
  const toggleAuth = () => {
    setIsAuthenticated(!isAuthenticated);
  };
  
  // Handle registration button click
  const handleRegisterClick = () => {
    if (isAuthenticated) {
      // Redirect to course if authenticated
      window.location.href = "#course-link"; // Replace with actual course URL
    } else {
      // Show modal if not authenticated
      setModalType('register');
      setShowModal(true);
    }
  };

  // Handle login button click
  const handleLoginClick = () => {
    setModalType('login');
    setShowModal(true);
  };
  
  return (
    <div className={`flex flex-col min-h-screen ${isDarkMode ? "bg-customBody" : "bg-white"}`}>
      <CodeForCambodiaHeader />
      
      <motion.div 
        className={`flex-grow ${isDarkMode ? "bg-customBody" : "bg-white"} font-sans overflow-x-hidden`}
        initial="initial"
        animate="animate"
        variants={stagger}
      >
        {/* Hero Section */}
        <motion.section 
          className={`text-center py-12 px-4 sm:px-6 lg:px-8 ${isDarkMode ? "" : "bg-gradient-to-b from-blue-50 to-white"}`}
          variants={fadeInUp}
        >
          <motion.div
            className="absolute top-20 right-10 hidden md:block"
            animate={{ y: [0, -10, 0], rotate: [0, 5, 0] }}
            transition={{ repeat: Infinity, duration: 3 }}
          >
            <img src="https://en.scratch-wiki.info/w/images/thumb/ScratchCat-Small.png/200px-ScratchCat-Small.png" alt="Scratch Cat" className="w-24 h-24" />
          </motion.div>
          
          <motion.h1 
            className={`text-3xl sm:text-4xl md:text-5xl font-bold mb-4 ${isDarkMode ? "text-customHeaderText" : ""}`}
            variants={fadeInUp}
          >
            <motion.span 
              className="text-blue-600 font-mono" 
              variants={fadeInUp}
              animate={{ scale: [1, 1.05, 1], color: ["#2563eb", "#4f46e5", "#2563eb"] }}
              transition={{ duration: 3, repeat: Infinity }}
            >
              Learn to Code with Scratch
            </motion.span>
            <br />
            <motion.span className="text-orange-500 Nokora" variants={fadeInUp}>
              រៀនសរសេរកូដជាមួយ Scratch
            </motion.span>
          </motion.h1>
          
          <motion.p 
            className={`text-lg sm:text-xl ${isDarkMode ? "text-customBodyText" : "text-gray-600"} mb-6 sm:mb-8 max-w-3xl mx-auto`}
            variants={fadeInUp}
          >
            Join our fun-filled adventure where kids 8-12 years old become coding superheroes! 
            Create awesome games, magical animations, and epic stories using Scratch!
          </motion.p>
          
          <motion.div className="flex justify-center gap-4" variants={fadeInUp}>
            <motion.button 
              className="font-mono bg-blue-600 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-300 text-sm sm:text-base flex items-center"
              onClick={handleRegisterClick}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Start Your Adventure!
              <ArrowRight className="ml-2 h-4 w-4" />
            </motion.button>
            
            <motion.button 
              className="font-mono bg-purple-600 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-semibold hover:bg-purple-700 transition duration-300 text-sm sm:text-base flex items-center"
              onClick={handleLoginClick}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Continue Journey
              <ArrowRight className="ml-2 h-4 w-4" />
            </motion.button>
          </motion.div>
        </motion.section>
        
        {/* Features Section */}
        <motion.section 
          className={`py-12 px-4 sm:px-6 lg:px-8 ${isDarkMode ? "bg-customBodyDarken1" : "bg-gradient-to-r from-blue-50 via-purple-50 to-blue-50"}`}
          variants={fadeInUp}
        >
          <div className="max-w-7xl mx-auto">
            <motion.h2 
              className={`text-2xl sm:text-3xl font-bold mb-6 sm:mb-8 text-center ${isDarkMode ? "text-customHeaderText" : ""}`}
              variants={fadeInUp}
            >
              <motion.span 
                className="text-blue-600 font-mono" 
                variants={fadeInUp}
                animate={{ y: [0, -5, 0] }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                Why Join Our Coding Adventure?
              </motion.span>
              <br />
              <motion.span className="text-orange-500 Nokora" variants={fadeInUp}>
                ហេតុអ្វីត្រូវរៀន Scratch?
              </motion.span>
            </motion.h2>
            
            <motion.div 
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8"
              variants={stagger}
            >
              {[
                { 
                  icon: <Code className="h-6 w-6 sm:h-8 sm:w-8 text-blue-600" />, 
                  title: "Level Up Through Play", 
                  description: "Earn XP and unlock achievements while creating awesome projects!" 
                },
                { 
                  icon: <Users className="h-6 w-6 sm:h-8 sm:w-8 text-orange-500" />, 
                  title: "Coding Wizards as Guides", 
                  description: "Our friendly instructors make learning magical and fun!" 
                },
                { 
                  icon: <GraduationCap className="h-6 w-6 sm:h-8 sm:w-8 text-blue-600" />, 
                  title: "Become a Problem-Solving Hero", 
                  description: "Develop superpowers to tackle any challenge!" 
                }
              ].map((item, index) => (
                <motion.div 
                  key={index} 
                  className={`flex flex-col items-center p-6 rounded-lg ${isDarkMode ? "bg-customBodyDarken1AndHalf" : "bg-white"} shadow-md border-2 border-blue-200`}
                  variants={fadeInUp}
                  whileHover={{ y: -5, boxShadow: "0 10px 25px -5px rgba(59, 130, 246, 0.5)" }}
                >
                  <motion.div 
                    className={`${isDarkMode ? "bg-customBodyDarken2" : "bg-gradient-to-r from-blue-100 to-purple-100"} p-3 sm:p-4 rounded-full mb-3 sm:mb-4`}
                    whileHover={{ scale: 1.1, rotate: 10 }}
                    animate={{ y: [0, -5, 0] }}
                    transition={{ duration: 2, repeat: Infinity, delay: index * 0.3 }}
                  >
                    {item.icon}
                  </motion.div>
                  <h3 className={`font-semibold mb-2 text-lg sm:text-xl ${isDarkMode ? "text-customHeaderText" : "text-blue-700"}`}>
                    {item.title}
                  </h3>
                  <p className={`${isDarkMode ? "text-customBodyText" : "text-gray-600"} text-center`}>
                    {item.description}
                  </p>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </motion.section>
        
        {/* Course Overview Section */}
        <motion.section 
          className={`py-12 px-4 sm:px-6 lg:px-8 ${isDarkMode ? "" : "bg-gradient-to-b from-white to-yellow-50"}`}
          variants={fadeInUp}
        >
          <div className="max-w-7xl mx-auto">
            <motion.h2 
              className={`text-2xl sm:text-3xl font-bold mb-6 sm:mb-8 text-center ${isDarkMode ? "text-customHeaderText" : ""}`}
              variants={fadeInUp}
            >
              <motion.span 
                className="text-blue-600 font-mono" 
                variants={fadeInUp}
                animate={{ color: ["#2563eb", "#7c3aed", "#2563eb"] }}
                transition={{ duration: 3, repeat: Infinity }}
              >
                Your Coding Quest Map
              </motion.span>
              <br />
              <motion.span className="text-orange-500 Nokora" variants={fadeInUp}>
                ទិដ្ឋភាពទូទៅនៃវគ្គសិក្សា
              </motion.span>
            </motion.h2>
            
            <motion.div 
              className={`grid grid-cols-1 md:grid-cols-2 gap-8 ${isDarkMode ? "text-customHeaderText" : ""}`}
              variants={fadeInUp}
            >
              <motion.div 
                className={`border-2 ${isDarkMode ? "border-blue-700 bg-customBodyDarken1AndHalf" : "border-blue-300 bg-blue-50"} rounded-lg p-6 relative overflow-hidden`}
                whileHover={{ scale: 1.02 }}
              >
                <div className="absolute top-0 right-0 w-20 h-20 bg-blue-500 rotate-45 translate-x-8 -translate-y-8 opacity-20"></div>
                <h3 className="text-xl font-bold mb-4 flex items-center">
                  <Zap className="mr-2 text-orange-500" />
                  {'<PowersYouWillGain>'}
                </h3>
                <ul className={`${isDarkMode ? "text-customBodyText" : "text-gray-700"} space-y-2`}>
                  <li className="flex items-center">
                    <span className="text-yellow-500 mr-2">⭐</span> 
                    Master the magic of coding blocks
                  </li>
                  <li className="flex items-center">
                    <span className="text-yellow-500 mr-2">⭐</span> 
                    Create amazing animations and stories
                  </li>
                  <li className="flex items-center">
                    <span className="text-yellow-500 mr-2">⭐</span> 
                    Design your own games with high scores
                  </li>
                  <li className="flex items-center">
                    <span className="text-yellow-500 mr-2">⭐</span> 
                    Solve puzzles with computational thinking
                  </li>
                  <li className="flex items-center">
                    <span className="text-yellow-500 mr-2">⭐</span> 
                    Share your creations with friends
                  </li>
                </ul>
              </motion.div>
              
              <motion.div 
                className={`border-2 ${isDarkMode ? "border-purple-700 bg-customBodyDarken1AndHalf" : "border-purple-300 bg-purple-50"} rounded-lg p-6 relative overflow-hidden`}
                whileHover={{ scale: 1.02 }}
              >
                <div className="absolute top-0 right-0 w-20 h-20 bg-purple-500 rotate-45 translate-x-8 -translate-y-8 opacity-20"></div>
                <h3 className="text-xl font-bold mb-4 flex items-center">
                  <Code className="mr-2 text-blue-600" />
                  {'<YourAdventureDetails>'}
                </h3>
                <ul className={`${isDarkMode ? "text-customBodyText" : "text-gray-700"} space-y-2`}>
                  <li className="flex items-center">
                    <span className="text-purple-500 mr-2">🔮</span> 
                    8-week quest with weekly missions
                  </li>
                  <li className="flex items-center">
                    <span className="text-purple-500 mr-2">🔮</span> 
                    Small party size (maximum 10 adventurers)
                  </li>
                  <li className="flex items-center">
                    <span className="text-purple-500 mr-2">🔮</span> 
                    Fun challenges and exciting quests
                  </li>
                  <li className="flex items-center">
                    <span className="text-purple-500 mr-2">🔮</span> 
                    No previous coding spells required
                  </li>
                  <li className="flex items-center">
                    <span className="text-purple-500 mr-2">🔮</span> 
                    Earn your Coding Hero Certificate
                  </li>
                </ul>
              </motion.div>
            </motion.div>
          </div>
        </motion.section>
        
        {/* Call to Action Section */}
        <motion.section 
          className={`py-12 px-4 sm:px-6 lg:px-8 text-center ${isDarkMode ? "bg-customBodyDarken1" : "bg-gradient-to-r from-blue-100 via-purple-100 to-blue-100"}`}
          variants={fadeInUp}
        >
          <div className="max-w-3xl mx-auto">
            <motion.h2 
              className={`text-2xl sm:text-3xl font-bold mb-4 ${isDarkMode ? "text-customHeaderText" : "text-blue-800"}`}
              variants={fadeInUp}
              animate={{ scale: [1, 1.03, 1] }}
              transition={{ duration: 3, repeat: Infinity }}
            >
              Ready to begin your coding adventure?
            </motion.h2>
            <motion.p 
              className={`text-lg mb-6 ${isDarkMode ? "text-customBodyText" : "text-gray-600"}`}
              variants={fadeInUp}
            >
              <span className="inline-block bg-yellow-300 text-yellow-800 px-3 py-1 rounded-full font-bold transform -rotate-2">
                Special Offer: $99.99 (One-time payment)
              </span>
            </motion.p>
            <motion.div className="flex justify-center gap-4">
              <motion.button 
                className="font-mono bg-orange-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-orange-600 transition duration-300 flex items-center"
                onClick={handleRegisterClick}
                whileHover={{ scale: 1.05, boxShadow: "0 10px 25px -5px rgba(249, 115, 22, 0.4)" }}
                whileTap={{ scale: 0.95 }}
              >
                Start Your Adventure!
                <ArrowRight className="ml-2 h-5 w-5" />
              </motion.button>
              
              <motion.button 
                className="font-mono bg-purple-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-purple-700 transition duration-300 flex items-center"
                onClick={handleLoginClick}
                whileHover={{ scale: 1.05, boxShadow: "0 10px 25px -5px rgba(124, 58, 237, 0.4)" }}
                whileTap={{ scale: 0.95 }}
              >
                Continue Journey
                <ArrowRight className="ml-2 h-5 w-5" />
              </motion.button>
            </motion.div>
          </div>
        </motion.section>
      </motion.div>
      
      {/* Registration/Login Modal */}
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => setShowModal(false)}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className={`${isDarkMode ? "bg-customBodyDarken1" : "bg-white"} rounded-lg p-6 max-w-md w-full m-4 border-4 ${modalType === 'register' ? 'border-blue-400' : 'border-purple-400'}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className={`text-2xl font-bold ${isDarkMode ? "text-customHeaderText" : modalType === 'register' ? "text-blue-600" : "text-purple-600"}`}>
                {modalType === 'register' ? 'Join the Coding Adventure!' : 'Continue Your Journey'}
              </h2>
              <button 
                onClick={() => setShowModal(false)} 
                className={`${isDarkMode ? "text-gray-400 hover:text-gray-200" : "text-gray-500 hover:text-gray-700"}`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <div className="text-center">
              {modalType === 'register' ? (
                <>
                  <div className="absolute top-2 right-2">
                    <motion.div
                      animate={{ rotate: [0, 10, 0, -10, 0] }}
                      transition={{ repeat: Infinity, duration: 2 }}
                    >
                      <span className="text-2xl">✨</span>
                    </motion.div>
                  </div>
                  <p className={`${isDarkMode ? "text-customBodyText" : "text-gray-600"} mb-6`}>
                    Join our Scratch coding adventure! Register through our Telegram channel to begin your child's journey.
                  </p>
                  <p className={`text-lg font-semibold ${isDarkMode ? "text-blue-400" : "text-blue-600"} mb-4`}>
                    <span className="inline-block bg-yellow-100 text-yellow-800 px-3 py-1 rounded-full transform -rotate-2">
                      Special Offer: $99.99 (One-time payment)
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <div className="absolute top-2 right-2">
                    <motion.div
                      animate={{ rotate: [0, 10, 0, -10, 0] }}
                      transition={{ repeat: Infinity, duration: 2 }}
                    >
                      <span className="text-2xl">🔮</span>
                    </motion.div>
                  </div>
                  <p className={`${isDarkMode ? "text-customBodyText" : "text-gray-600"} mb-6`}>
                    Welcome back, coding hero! Log in to continue your Scratch adventure.
                  </p>
                </>
              )}
              
              <div className="flex flex-col space-y-3">
                {modalType === 'register' ? (
                  <a 
                    href="https://t.me/codeforcambodia_support" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition duration-300 flex items-center justify-center"
                  >
                    <span className="mr-2">🚀</span>
                    Register via Telegram
                  </a>
                ) : (
                  <a 
                    href="https://t.me/codeforcambodia_support" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-lg transition duration-300 flex items-center justify-center"
                  >
                    <span className="mr-2">🔑</span>
                    Login via Telegram
                  </a>
                )}
                
                <button 
                  onClick={() => setModalType(modalType === 'register' ? 'login' : 'register')}
                  className={`${isDarkMode ? "bg-gray-700 hover:bg-gray-600 text-gray-200" : "bg-gray-200 hover:bg-gray-300 text-gray-800"} py-2 px-4 rounded-lg transition duration-300`}
                >
                  {modalType === 'register' ? 'Already have an account? Login' : 'Need an account? Register'}
                </button>
                
                <button 
                  onClick={() => setShowModal(false)}
                  className={`${isDarkMode ? "bg-gray-700 hover:bg-gray-600 text-gray-200" : "bg-gray-200 hover:bg-gray-300 text-gray-800"} py-2 px-4 rounded-lg transition duration-300`}
                >
                  Close
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
      
      <Footer />
    </div>
  );
};

export default ScratchLandingPage;